






















































































































































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { mask } from 'vue-the-mask'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import ArrowUp16 from '@carbon/icons-vue/es/arrow--up/16'
import ArrowDown16 from '@carbon/icons-vue/es/arrow--down/16'

import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'

@Component({
  directives: { mask },
  components: {
    ArrowDown16,
    ArrowUp16,
    TrashCan16,
    RichTextEditor,
    NotifyOnFailed,
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})

export default class ScheduleForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) scheduleId!: string

  globalKey = 0
  done = true
  form: Record<string, any> = {
    datetime: '',
    year: '',
    month: '',
    day: '',
    part: '',
    title: '',
    description: '',
    eventId: '',
    order: 999,
    module: false,
    bordered: true,
    published: true,
    speakers: [],
    confirm: '',
    errors: {
      datetime: '',
      year: '',
      month: '',
      day: '',
      part: '',
      title: '',
      description: '',
      eventId: '',
      module: '',
      bordered: '',
      published: '',
      order: '',
      confirm: ''
    }
  }

  parts = [
    {
      name: 'morning',
      prettyName: 'Manhã (06:00 até 11:59)'
    },
    {
      name: 'afternoon',
      prettyName: 'Tarde (12:00 até 17:59)'
    },
    {
      name: 'evening',
      prettyName: 'Noite (18:00 até 23:59)'
    },
    {
      name: 'night',
      prettyName: 'Madrugada (00:00 até 05:59)'
    }
  ]

  roles = [
    { name: 'palestrante', prettyName: 'Palestrante' },
    { name: 'moderador', prettyName: 'Moderador' },
    { name: 'coordenador', prettyName: 'Coordenador' },
    { name: 'secretário', prettyName: 'Secretário' },
    { name: 'debatedor', prettyName: 'Debatedor' },
    { name: 'instrutor', prettyName: 'Instrutor' },
    { name: 'apresentador', prettyName: 'Apresentador' }
  ]

  selectedSpeakers = [
    {
      speakerId: '',
      theme: '',
      time: '',
      role: 'palestrante',
      key: 0,
      order: 0
    }
  ]

  eventSpeakers = []

  removeEventScheduleSpeaker (key: number) {
    this.selectedSpeakers = this.selectedSpeakers.filter((eventScheduleSpeaker) => eventScheduleSpeaker.key !== key)
  }

  addEventScheduleSpeaker () {
    this.selectedSpeakers.push(
      {
        speakerId: '',
        theme: '',
        time: '',
        role: 'palestrante',
        key: this.globalKey++,
        order: this.selectedSpeakers.length
      })
  }

  moveChoice (index: number, direction: string) {
    let targetIndex: number

    if (direction === 'up') {
      targetIndex = index - 1
    } else {
      targetIndex = index + 1
    }

    const swappingChoice = this.selectedSpeakers[targetIndex]

    this.selectedSpeakers.splice(targetIndex, 1)
    this.selectedSpeakers.splice(index, 0, swappingChoice)

    this.selectedSpeakers = this.selectedSpeakers.map((speaker, index) => ({
      ...speaker,
      order: index
    }))
  }

  created () {
    this.form.eventId = this.eventId

    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        datetime: '08:00 às 10:00',
        title: 'Programação Científica',
        description: `
          <h1>Título Teste</h1>
          <p>Esse é um paragrafo de teste</p>
          <ul>
            <li><strong>Lista 1</strong></li>
            <li><s>Lista 2</s></li>
            <li>Lista 3</li>
          </ul>
          <blockquote>Isso é uma citação</blockquote>
        `
      })
    }

    if (this.type === 'create') {
      axios.get(`/event/${this.eventId}/schedules`)
        .then(() => {
          this.form.order = 0
        })
    } else if (this.type === 'edit') {
      axios.get(`/event/${this.eventId}/schedules/${this.scheduleId}`)
        .then(response => {
          const schedule = camelCaseKeys(response.data.data, { deep: true })
          Object.keys(schedule).forEach((key: string) => {
            this.form[key] = key in this.form ? schedule[key] : this.form[key]
          })

          this.globalKey = 0
          this.selectedSpeakers = schedule.scheduleSpeakers.map((speaker: any) => {
            return {
              speakerId: speaker.eventSpeakerId,
              role: speaker.role.toLowerCase(),
              theme: speaker.theme,
              time: speaker.time,
              order: speaker.order,
              key: this.globalKey++
            }
          })
        })

      axios.get(`/event/${this.eventId}/speakers/dropdown`)
        .then(response => {
          this.eventSpeakers = camelCaseKeys(response.data.data, { deep: true })
        })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit'
      ? `/event/${this.eventId}/schedules/${this.scheduleId}`
      : `/event/${this.eventId}/schedules`

    this.selectedSpeakers.forEach(speaker => {
      this.form.speakers.push(snakeCaseKeys(speaker))
    })

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess () {
    const successVerb = this.type === 'edit' ? 'editada' : 'cadastrada'

    const flashMessage = {
      message: `A Programação Científica foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({
      name: 'EventSchedules',
      params: {
        id: this.eventId
      }
    }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  resetErrors () {
    this.form.errors = {
      eventId: '',
      confirm: ''
    }
  }
}
