var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.type === 'edit')?_c('div',{staticClass:"flex justify-center items-center"},[_c('ProfileUploader',{attrs:{"img-url":_vm.profilePictureURL,"owner-id":_vm.$route.params.eventId,"picture-id":_vm.pictureId,"type":"event"},on:{"image-loaded":function($event){_vm.form.picture = $event},"deleted":function($event){_vm.profilePictureURL = ''; _vm.pictureId = ''}}})],1):_vm._e(),_c('ValidationObserver',{staticClass:"pb-8",attrs:{"tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var failed = ref.failed;
var untouched = ref.untouched;
var reset = ref.reset;
return [_c('h1',{staticClass:"text-3xl font-bold"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('div',{staticClass:"grid grid-cols-1"},[_c('form',{on:{"submit":function($event){$event.preventDefault();_vm.resetErrors(); _vm.syncTimeout(reset, function () { return handleSubmit(_vm.submit); })}}},[(_vm.type === 'create')?_c('p',{staticClass:"text-sm pt-8"},[_vm._v(" A foto poderá ser inserida após cadastrar o Palestrante. ")]):_vm._e(),_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"py-4 lg:col-span-12",attrs:{"name":"name","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Nome","invalid-message":_vm.form.errors.name || errors[0]},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})]}}],null,true)})],1),_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"lg:col-span-3",attrs:{"tag":"div","rules":"required","name":"type"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-dropdown',{attrs:{"label":"País"},model:{value:(_vm.form.country),callback:function ($$v) {_vm.$set(_vm.form, "country", $$v)},expression:"form.country"}},_vm._l((_vm.countries),function(country){return _c('cv-dropdown-item',{key:country.flag,attrs:{"value":country.flag,"selected":(country.flag === 'br' && _vm.type !== 'edit'),"invalid-message":_vm.form.errors.country || errors[0]}},[_vm._v(" "+_vm._s(country.name)+" ")])}),1)]}}],null,true)}),_c('cv-text-input',{staticClass:"lg:col-span-2",attrs:{"label":"UF (opcional)"},model:{value:(_vm.form.uf),callback:function ($$v) {_vm.$set(_vm.form, "uf", $$v)},expression:"form.uf"}}),_c('cv-text-input',{staticClass:"lg:col-span-2",attrs:{"label":"Cidade (opcional)"},model:{value:(_vm.form.city),callback:function ($$v) {_vm.$set(_vm.form, "city", $$v)},expression:"form.city"}}),_c('cv-text-input',{staticClass:"lg:col-span-3",attrs:{"label":"Instituição (opcional)"},model:{value:(_vm.form.institution),callback:function ($$v) {_vm.$set(_vm.form, "institution", $$v)},expression:"form.institution"}}),_c('cv-text-input',{staticClass:"lg:col-span-2",attrs:{"label":"Especialidade (opcional)"},model:{value:(_vm.form.specialty),callback:function ($$v) {_vm.$set(_vm.form, "specialty", $$v)},expression:"form.specialty"}})],1),_c('div',{staticClass:"grid lg:grid-cols-12 gap-4 mt-8"},[_c('ValidationProvider',{staticClass:"py-4 lg:col-span-full",attrs:{"name":"description","rules":"required","tag":"div"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cv-text-input',{attrs:{"label":"Descrição/Mini CV","invalid-message":_vm.form.errors.description || errors[0]},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})]}}],null,true)}),_c('cv-checkbox',{staticClass:"lg:col-span-2",attrs:{"label":"Publicado no site","value":"true"},model:{value:(_vm.form.published),callback:function ($$v) {_vm.$set(_vm.form, "published", $$v)},expression:"form.published"}})],1),_c('div',{staticClass:"pt-8"},[_c('div',{staticClass:"w-full lg:w-1/2"},[_c('PasswordConfirmField',{attrs:{"invalid-message":_vm.form.errors.confirm},model:{value:(_vm.form.confirm),callback:function ($$v) {_vm.$set(_vm.form, "confirm", $$v)},expression:"form.confirm"}})],1),_c('div',[(_vm.done)?_c('cv-button',{staticClass:"whitespace-no-wrap mt-1",attrs:{"type":"submit"}},[_vm._v(" Enviar ")]):_c('cv-button-skeleton')],1)])])]),_c('NotifyOnFailed',{attrs:{"failed":failed,"untouched":untouched}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }