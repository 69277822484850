































































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { mask } from 'vue-the-mask'

import NotifyOnFailed from '@/partials/forms/NotifyOnFailed.vue'
import ValidatorConfigMixin from '@/mixins/validatorConfigMixin'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PasswordConfirmField from '@/partials/forms/PasswordConfirmField.vue'
import RichTextEditor from '@/partials/forms/components/RichTextEditor.vue'
import ProfileUploader from '@/partials/components/ProfileUploader.vue'

@Component({
  directives: { mask },
  components: {
    ProfileUploader,
    RichTextEditor,
    NotifyOnFailed,
    PasswordConfirmField,
    ValidationProvider,
    ValidationObserver
  }
})

export default class SpeakerForm extends Mixins(ValidatorConfigMixin, FlashMessageMixin) {
  @Prop({ required: true, type: String }) title!: string
  @Prop({ required: true, type: String }) type!: 'create' | 'edit'
  @Prop({ required: true, type: String }) eventId!: string
  @Prop({ type: String }) speakerId!: string

  done = true
  profilePictureURL = ''
  pictureId = ''
  form: Record<string, any> = {
    name: '',
    country: '',
    uf: '',
    city: '',
    institution: '',
    specialty: '',
    description: '',
    published: '',
    eventId: '',
    confirm: '',
    errors: {
      name: '',
      country: '',
      uf: '',
      city: '',
      institution: '',
      specialty: '',
      description: '',
      eventId: '',
      confirm: ''
    }
  }

  countries = []

  created () {
    this.form.eventId = this.eventId

    axios.get('geo/countries')
      .then(response => {
        this.countries = response.data.data
      })

    if (process.env.NODE_ENV === 'development' && this.type !== 'edit') {
      Object.assign(this.form, {
        name: 'Palestrante',
        description: 'Mini CV do Palestrante...',
        published: true
      })
    }

    if (this.type === 'create') {
      axios.get(`/event/${this.eventId}/speakers`)
        .then(() => {
          this.form.order = 0
        })
    } else if (this.type === 'edit') {
      axios.get(`/event/${this.eventId}/speakers/${this.speakerId}`)
        .then(response => {
          const speaker = camelCaseKeys(response.data.data, { deep: true })
          Object.keys(speaker).forEach((key: string) => {
            this.form[key] = key in this.form ? speaker[key] : this.form[key]

            if (speaker.picture) {
              this.profilePictureURL = speaker.picture.publicPath
              this.pictureId = speaker.picture.id
            }
          })
        })
    }
  }

  submit () {
    this.done = false
    const verb = this.type === 'edit' ? 'put' : 'post'
    const route = this.type === 'edit'
      ? `/event/${this.eventId}/speakers/${this.speakerId}`
      : `/event/${this.eventId}/speakers`

    axios[verb](route, snakeCaseKeys(this.form))
      .then(() => {
        this.redirectOnSuccess()
      })
      .catch(err => this.handleSubmitError(err))
      .finally(() => { this.done = true })
  }

  redirectOnSuccess () {
    const successVerb = this.type === 'edit' ? 'editado' : 'cadastrado'

    const flashMessage = {
      message: `O Palestrante foi ${successVerb} com sucesso.`,
      kind: 'success'
    }

    this.$router.push({
      name: 'EventSpeakers',
      params: {
        id: this.eventId
      }
    }, () => this.setFlashMessage(flashMessage))
  }

  handleSubmitError (err: any) {
    const response = err.response

    const errors = camelCaseKeys(response.data.errors, { deep: true })
    if (errors) {
      Object.entries(errors).map(([key, value]: [string, any]) => {
        this.form.errors[key] = value[0]
      })
    }
  }

  windowWidth = window.innerWidth
  mounted () {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  }

  beforeDestroy () {
    window.removeEventListener('resize', this.onResize)
  }

  onResize () {
    this.windowWidth = window.innerWidth
  }

  resetErrors () {
    this.form.errors = {
      eventId: '',
      confirm: ''
    }
  }
}
